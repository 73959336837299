#policy {
  padding: 50px 100px;
}

#policy ul {
  list-style-type: lower-alpha;
}

#policy a {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@media (max-width: 1050px) {
  #policy {
    padding: 25px 50px;
  }
}

@media (max-width: 750px) {
  #policy {
    padding: 10px;
  }

  #policy ol {
    padding-left: 20px;
  }

  #policy Ul {
    margin-left: 20px;
  }
}
