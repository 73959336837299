#price-list p {
  padding: 10px;
  text-align: justify;
  line-height: 1.5;
}

#pricing {
  border-collapse: collapse;
  width: calc(100% - 20px);
  margin: 10px;
}

#pricing td,
#pricing th {
  border: 1px solid #ddd;
  padding: 8px;
}

#pricing tr:nth-child(even) {
  background-color: #f2f2f2;
}

#pricing tr:hover {
  background-color: #ddd;
}

#pricing th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #526d82;
  color: white;
}
