#nav {
  height: 150px;
}

.navbar {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
}

.navbar-top {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  background-color: #95b3d7;
  padding: 10px;
}

.navbar-bottom {
  width: 100%;
  height: auto;
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 100px;
}

.navbar-bottom-visible {
  background-color: #ffffff;
}

.navbar ul {
  text-align: center;
  margin: 0;
}

.navbar li {
  display: inline-block;
  padding: 0;
  line-height: unset;
}

.navbar .logo {
  height: 60px;
  padding: 0px;
  display: flex;
  align-items: center;
}

.navbar .logo-visible {
  height: 100px;
}

.navbar .logo img {
  width: 200px;
}

.navbar .logo-visible img {
  width: 350px;
}

.navbar-top a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  height: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 0;
}

.navbar-top span {
  margin-left: 10px;
}

.navbar-top a:hover span {
  border-bottom: white 1px solid;
}

.navbar-bottom ul a {
  color: rgb(46, 46, 46);
  padding: 16px;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: 0.2s;
}

.navbar-bottom .expanded {
  height: 100px;
  font-size: 25px;
}

#mobile-navbar {
  display: none;
}

.navbar-bottom li a:hover,
#mobile-drawer li:hover {
  background-color: #dadada;
}

#swipeable-drawer * {
  background: transparent;
}

#mobile-drawer {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  height: 100%;
}

#mobile-drawer li {
  list-style-type: none;
  padding: 0;
}

#mobile-drawer a {
  text-decoration: none;
  padding: 20px 50px;
  font-size: 20px;
  font-weight: 600;
  color: rgb(46, 46, 46);
  transition: 0.2s;
  display: block;
}

@media (max-width: 1400px) {
  #nav {
    height: 120px;
  }

  .navbar-bottom .expanded {
    font-size: 22px;
    height: 80px;
  }

  .navbar-bottom ul a {
    padding: 12px;
    font-size: 19px;
    height: 50px;
  }

  .navbar .logo {
    height: 50px;
  }

  .navbar .logo-visible {
    height: 80px;
  }

  .navbar .logo img {
    width: 180px;
  }

  .navbar .logo-visible img {
    width: 300px;
  }

  .navbar-top a {
    font-size: 14px;
    font-weight: 500;
  }

  .navbar-top {
    padding: 5px;
  }
}

@media (max-width: 1240px) {
  #nav {
    height: 90px;
  }

  .navbar .logo-visible {
    height: 50px;
  }

  .navbar .logo-visible img {
    width: 180px;
  }

  .navbar-bottom .expanded {
    height: 50px;
    font-size: 19px;
  }
}

@media (max-width: 1050px) {
  .navbar-bottom {
    padding-left: 15px;
    padding-right: 25px;
  }
}

@media (max-width: 880px) {
  .navbar ul {
    display: none;
  }
  .navbar-bottom {
    padding-right: 0;
  }
  #mobile-navbar {
    display: block;
  }
}

@media (max-width: 760px) {
  .navbar-top {
    display: none;
  }
  #nav {
    height: 80px;
  }

  .navbar .logo-visible {
    height: 80px;
  }

  .navbar img {
    width: 250px;
  }
}

@media (max-width: 380px) {
  #nav {
    height: 70px;
  }

  .navbar .logo-visible {
    height: 70px;
  }

  .navbar .logo-visible img {
    width: 180px;
  }
}
