#organizations {
  justify-content: space-around;
  display: flex;
}

#org-section {
  margin: 35px auto;
}

#organizations img {
  height: 70px;
}

#organizations a {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 750px) {
  #organizations {
    flex-direction: column-reverse;
    align-items: center;
  }
  #organizations a {
    margin: 20px;
  }
}
