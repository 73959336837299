.windows {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 950px) {
  .windows {
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
  }
}