@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap");

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-family: "Open Sans";
}

h1,
h2,
h3,
h4 {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: disc;
  margin-left: 2em;
  text-align: left;
}

li {
  line-height: 1.5;
  padding: 5px;
}

.section {
  width: 1200px;
  margin: 50px auto;
}

.shifted-anchor {
  display: block;
  position: relative;
  top: -110px;
}

@media (max-width: 1300px) {
  .section {
    width: auto;
  }
}

@media (max-width: 950px) {
  .section {
    margin: 10px auto;
  }
  .shifted-anchor {
    top: -90px;
  }
}

@media (max-width: 760px) {
  .shifted-anchor {
    top: -50px;
  }
}

@media (max-width: 700px) {
  html,
  body {
    font-size: 17px;
  }
}

@media (max-width: 400px) {
  html,
  body {
    font-size: 15px;
  }
}
