.window {
  display: block;
  width: 500px;
  line-height: 1.5;
  margin: 10px;
  border: #526d82 1px solid;
  background-color: #f2f2f2;
}

.window h3 {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0.8px;
}

.no-bar {
  color: #364c5e;
  margin-top: 20px;
}

.blue-bar {
  padding: 10px;
  background-color: #95b3d7;
  color: white;
}

.window ul {
  padding: 20px;
}

.window p {
  margin: 20px;
  white-space: break-spaces;
}

@media (max-width: 1200px) {
  .window {
    width: calc(50% - 10px);
  }
}

@media (max-width: 950px) {
  .window {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .window h3 {
    font-size: 19px;
  }
}

@media (max-width: 400px) {
  .window h3 {
    font-size: 15px;
  }
}
