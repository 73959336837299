@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.hamburger {
  width: 60px;
  height: 100%;
  background-color: transparent;
  color: #526d82;
  text-decoration: none;
  transition-duration: 0.2s;
  cursor: pointer;
  border: none;
  padding: 0;
  display: block;
}

.hamburger:hover {
  background-color: #526d82;
  color: #dde6ed;
}

.button {
  width: 390px;
  height: 60px;
  background-color: #526d82;
  color: #dde6ed;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition-duration: 0.2s;
  cursor: pointer;
  font-size: 19px;
  font-weight: bold;
  font-family: "Open Sans";
  border: #526d82 solid 2px;
  padding: 0;
}

.button:hover {
  background-color: #dde6ed;
  color: #526d82;
}

.outlined {
  width: 190px;
  background-color: #dde6ed;
  color: #526d82;
}

.outlined:hover {
  background-color: #526d82;
  color: #dde6ed;
}

.button svg {
  display: block;
  margin: 0 0 0 20px;
}

.button span {
  display: block;
  margin: 0 auto;
}

.button_a {
  text-decoration: none;
}

@media (max-width: 1240px) {
  .button {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .button {
    font-size: 17.5px;
  }
}

@media (max-width: 400px) {
  .button {
    font-size: 16px;
  }
}