.empty {
  width: 550px;
  background-color: white;
  padding: 20px;
  margin: 10px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.step-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1200px) {
  .empty {
    width: 20%;
  }
}

@media (max-width: 950px) {
  .empty {
    display: none;
  }
}
