.number {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background: #fcfdff;
  border: 3px solid #526d82;
  color: #526d82;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.number span {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

@media (max-width: 950px) {
    .number {
      display: none;
    }
  }