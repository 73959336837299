#kontakt {
  background-color: #141414;
  color: #9e9e9e;
  padding: 0 200px;
  display: flex;
}

.contact {
  display: flex;
  justify-content: space-between;
}

footer a {
  text-decoration: none;
  color: #9e9e9e;
}

footer a:hover {
  border-bottom: #9e9e9e 1px solid;
}

footer h2 {
  font-weight: bold;
}

.contact-area {
  margin-top: 25px;
  margin-left: 50px;
  display: inline-block;
}

.contact-area-right {
  text-align: center;
}

#bottom-footer {
  background-color: #141414;
  padding-bottom: 20px;
}

#bottom-footer a {
  color: #9e9e9e;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  font-size: 8px;
  letter-spacing: 0.8px;
}

#bottom-footer h4,
#bottom-footer span {
  padding: 5px;
  color: #9e9e9e;
  text-transform: none;
  text-align: center;
  display: block;
  font-size: 15px;
}

@media (max-width: 1300px) {
  #kontakt {
    padding: 0;
  }
  #kontakt .section {
    margin: 50px;
    width: 100%;
  }
}

@media (max-width: 900px) {
  #kontakt .section {
    margin: 30px;
    width: 100%;
  }

  .contact-area {
    margin-top: 15px;
    margin-left: 20px;
  }
}

@media (max-width: 550px) {
  .contact-area {
    margin-top: 0;
  }

  .contact {
    flex-direction: column;
  }

  .contact-area-right {
    text-align: start;
  }

  .contact-area-right strong {
    display: block;
  }
}

@media (max-width: 400px) {
  #kontakt .section {
    margin: 15px;
  }

  .contact-area {
    margin-left: 10px;
  }
}
