.not-found-banner {
  position: absolute;
  left: 50%;
  top: 200px;
  transform: translate(-50%, 0%);
  width: 60%;
  height: auto;
  backdrop-filter: blur(5px);
  background-color: rgba(221, 230, 237, 0.8);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  padding: 100px;
  white-space: pre-wrap;
}

.not-found-banner h2 {
  font-size: 200px;
  font-weight: 600;
  margin: 0;
  color: #526d82;
}

.not-found-banner h4 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  color: black;
}

.banner {
  position: absolute;
  right: 150px;
  top: 310px;
  width: 700px;
  height: auto;
  backdrop-filter: blur(8px);
}

.banner_background {
  width: 100%;
  height: 100%;
  background-color: #dde6ed7c;
  padding: 40px 40px 20px;
  box-sizing: border-box;
  transition-duration: 0.7s;
}

.banner_background:hover {
  transition-duration: 0.3s;
  background-color: #dde6edd5;
}

.banner_h {
  color: #202124;
  margin: 0;
}

.banner h1 {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.banner h2 {
  text-align: right;
  font-size: 32px;
}

.banner h3 {
  text-align: start;
  font-size: 30px;
}

.banner_row {
  width: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
}

@media (max-width: 1700px) {
  .banner {
    right: 100px;
    top: 250px;
    width: 700px;
  }

  .banner_background {
    padding: 30px;
  }

  .banner h1 {
    font-size: 35px;
  }

  .banner h2 {
    font-size: 28px;
  }

  .banner h3 {
    font-size: 25px;
  }
}

@media (max-width: 1400px) {
  .not-found-banner {
    padding: 80px;
  }

  .not-found-banner h2 {
    font-size: 150px;
  }

  .not-found-banner h4 {
    font-size: 20px;
  }

  .banner {
    right: 80px;
    top: 220px;
    width: 650px;
  }

  .banner_background {
    padding: 25px;
  }

  .banner h1 {
    font-size: 31px;
  }

  .banner h2 {
    font-size: 25px;
  }

  .banner h3 {
    font-size: 24px;
  }
}

@media (max-width: 1240px) {
  .not-found-banner {
    padding: 60px;
  }

  .not-found-banner h2 {
    font-size: 120px;
  }

  .not-found-banner h4 {
    font-size: 20px;
  }

  .banner {
    right: 40px;
    top: 130px;
    width: 450px;
  }

  .banner_background {
    padding: 25px;
  }

  .banner_row {
    flex-direction: column;
    gap: 5px;
  }

  .banner h1 {
    font-size: 24px;
  }

  .banner h2 {
    font-size: 22px;
  }

  .banner h3 {
    font-size: 20px;
  }
}

@media (max-width: 1050px) {
  .not-found-banner {
    width: 80%;
    padding: 80px;
  }

  .banner {
    left: 50%;
    transform: translate(-50%, 0%);
    width: 60%;
  }

  .banner h1 {
    font-size: 28px;
  }

  .banner h2 {
    font-size: 25px;
  }

  .banner h3 {
    font-size: 23px;
  }
}

@media (max-width: 820px) {
  .not-found-banner {
    top: 150px;
    padding: 60px;
  }

  .not-found-banner h2 {
    font-size: 120px;
  }

  .banner {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .banner {
    width: 90%;
  }
}

@media (max-width: 510px) {
  .not-found-banner h2 {
    font-size: 100px;
  }

  .banner {
    width: calc(100% - 20px);
  }

  .banner_background {
    padding: 10px;
  }

  .banner_row {
    margin-top: 15px;
  }

  .banner h1 {
    font-size: 24px;
  }

  .banner h2 {
    font-size: 20px;
  }

  .banner h3 {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .not-found-banner {
    padding: 40px 10px;
    width: calc(100% - 20px);
  }

  .not-found-banner h2 {
    font-size: 80px;
  }

  .banner h1,
  .banner h2,
  .banner h3 {
    text-align: center;
  }

  .banner h1 {
    font-size: 18px;
  }

  .banner h2 {
    font-size: 17px;
  }

  .banner h3 {
    font-size: 16px;
  }
}
