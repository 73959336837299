#valuation-purposes {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

#valuation-purposes ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  column-gap: 100px;
}

@media (max-width: 950px) {
  #valuation-purposes ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}