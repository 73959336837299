.documents {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .doc-column {
    width: 50%;
  }

  .documents .window {
    width: calc(100% - 20px);
  }
}

@media (max-width: 950px) {
  .documents {
    flex-direction: column;
  }

  .doc-column {
    width: 100%;
  }
}
