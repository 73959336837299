.section-header h2 {
  text-align: center;
  background-color: #95b3d7;
  color: white;
  padding: 25px;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 1px;
}

@media (max-width: 700px) {
  .section-header h2 {
    font-size: 25px;
  }
}

@media (max-width: 400px) {
  .section-header h2 {
    font-size: 20px;
  }
}
