#about p {
  text-align: center;
  margin-top: 0;
}

#about h4 {
  text-align: center;
  margin-top: 50px;
}

#about h4:first-of-type {
  margin-top: 0;
}

#about .section {
  padding: 10px;
}
